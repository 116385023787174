import PropTypes from "prop-types";
import React from "react";
import { Facebook, Linkedin, Mail, Twitter } from "react-social-sharing";
import Box from "../../base/Box";

const ShareCTA = ({ link, sx, title }) => (
  <Box
    sx={{
      alignItems: ["flex-start", null, null, "center"],
      display: "flex",
      flexDirection: ["column", null, "row"],
      fontSize: [1, null, null, 2],
      mx: "auto",
      width: "100%",
      ...sx,
    }}
  >
    <Box
      as="h4"
      sx={{
        display: "flex",
        fontSize: [1, null, null, 2],
        mr: [null, null, 3],
        my: "auto",
      }}
    >
      SHARE LINKS
    </Box>
    <Box sx={{ ml: ["-0.5em", null, null, null], mt: 0 }}>
      <Linkedin link={link} message={title} small solid />
      <Twitter link={link} message={title} small solid />
      <Facebook link={link} message={title} small solid />
      <Mail link={link} message={title} small solid />
    </Box>
  </Box>
);

ShareCTA.propTypes = {
  link: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
};

ShareCTA.defaultProps = {
  sx: {},
};

export default ShareCTA;
