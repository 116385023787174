import React, { useContext } from "react";
import PropTypes from "prop-types";
import { LikeButton, Provider } from "@lyket/react";
import { ThemeContext } from "styled-components";
import Box from "../../base/Box";
import MarkdownToHtml from "../MarkdownToHtml";
import FooterContainer from "../../containers/FooterContainer";
import SocialLinks from "../socialLink";
import Section from "../Section";
import config from "../../config.json";
import SEO from "../SEO/seo";
import ShareCTA from "../ShareCTA";
import { titleToPath } from "../../helper/helper";

const ArticleLayout = ({ pageContext, location }) => {
  const theme = useContext(ThemeContext);
  const { articleData } = pageContext;
  const { handWrittenArticle, date, title } = articleData;
  return (
    <FooterContainer>
      {(footerData) => {
        const footerId = "footer";
        const footerInfo = footerData.find((data) => data.id === footerId);
        const { socialmediacontacts, hideSocialMedia } = footerInfo;
        const lyketId = titleToPath(title);
        return (
          <>
            <SEO pathname="news" templateTitle={`News | ${title}`} />
            <Section>
              <Box
                sx={{
                  mb: [2, null, null, 5],
                  textAlign: "left",
                  ...theme.designElement.overLine,
                }}
                variant="markdown.h2"
              >
                News
              </Box>
              <Box
                sx={{
                  "&:before": {
                    bg: "inherit",
                    content: '""',
                    height: "100%",
                    left: "50%",
                    ml: "-50vw",
                    position: "absolute",
                    top: 0,
                    width: "100vw",
                    zIndex: "-1",
                  },
                  bg: "blueLight",
                  color: "white",
                  fontSize: 3,
                  mb: 5,
                  p: { color: "white" },
                  position: "relative",
                  py: 4,
                  textAlign: "left",
                }}
              >
                {title}
              </Box>
              <MarkdownToHtml
                content={handWrittenArticle}
                sx={{
                  fontSize: [1, null, null, 2],
                  h1: {
                    fontSize: ["50px", null, null, "80px"],
                  },
                  "h1, h2, h3, h4, h5, h6": {
                    color: "yellowDark",
                    fontWeight: "medium",
                    textAlign: "left",
                  },
                  h2: {
                    fontSize: ["40px", null, null, "60px"],
                  },
                  h3: {
                    fontSize: ["35px", null, null, "50px"],
                  },
                  h4: {
                    fontSize: ["30px", null, null, "40px"],
                  },
                  h5: {
                    fontSize: ["25px", null, null, "30px"],
                  },
                  h6: {
                    fontSize: ["20px", null, null, "25px"],
                  },
                  mb: [4, null, null, 5],
                  textAlign: "justify",
                  "ul, ol": {
                    li: { textAlign: "justify" },
                  },
                }}
              />
              <Box
                sx={{
                  alignItems: "center",
                  color: "yellowDark",
                  display: "flex",
                  flexWrap: "wrap",
                  fontSize: [1, null, null, 2],
                }}
              >
                <Box sx={{ width: "50%" }}>{date}</Box>
                {config.lyketApiKey && (
                  <Provider apiKey={config.lyketApiKey}>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "50%",
                      }}
                    >
                      <LikeButton
                        component={LikeButton.templates.Twitter}
                        id={lyketId.slice(0, 40)}
                        namespace="news"
                      />
                      <Box as="span" sx={{ color: "text.para", ml: 2 }}>
                        Likes
                      </Box>
                    </Box>
                  </Provider>
                )}
                <ShareCTA link={location.href} title={title} />
                {!hideSocialMedia && socialmediacontacts.length > 0 && (
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      mt: [2, null, null, 4],
                      width: "100%",
                    }}
                  >
                    <p>FOLLOW US</p>
                    <Box
                      sx={{
                        ml: [3, null, null, 5],
                      }}
                    >
                      <SocialLinks
                        iconSx={{
                          alignItems: "center",
                          display: "flex",
                          height: ["1.5rem", null, null, "3rem"],
                          mr: [2, null, null, 4],
                          width: ["1.5rem", null, null, "3rem"],
                        }}
                        links={socialmediacontacts.map((sm) => ({
                          icon: `${sm.label}-sm`,
                          link: sm.link,
                        }))}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Section>
          </>
        );
      }}
    </FooterContainer>
  );
};

ArticleLayout.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    articleData: PropTypes.shape({
      articleType: PropTypes.string.isRequired,
      date: PropTypes.string,
      document: PropTypes.string.isRequired,
      handWrittenArticle: PropTypes.string,
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  }).isRequired,
};

export default ArticleLayout;
